import React from "react";
import { HashRouter, Route, Link, Switch } from "react-router-dom";
import { bubble as Menu } from "react-burger-menu";

import Home from "../pages/index";
import Courses from "../pages/courses/index";
import Tutorials from "../pages/tutorials/index";
import Error from "../layouts/error";

import { FaExternalLinkAlt, FaDotCircle } from "react-icons/fa";

let navStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "32px",
    height: "32px",
    left: "20px",
    top: "15px",
  },
  bmBurgerBars: {
    background: "black",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "black",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: "#4b4e67",
    padding: "0",
    fontSize: "1.15em",
    width: "100%",
  },
  bmMorphShape: {
    fill: "#4b4e67",
  },
  bmItemList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  bmItem: {
    display: "inline-block",
    color: "white",
    outline: "none",
    margin: "1em",
    listStyle: "none",
    textDecoration: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.5)",
    top: 0,
    left: 0,
  },
};

export default function Sidebar() {
  return (
    <div className="">
      <HashRouter>
        <Menu styles={navStyles}>
          <nav>
            <ul>
              <li>
                <Link className="inline-flex" to="/">
                  <span>
                    <FaDotCircle style={{ width: "20px", marginTop: "0.4rem", marginRight: "0.5rem" }} />
                  </span>
                  Accueil
                </Link>
              </li>
              <li>
                <Link className="inline-flex" to="/cours">
                  <span>
                    <FaDotCircle style={{ width: "20px", marginTop: "0.4rem", marginRight: "0.5rem" }} />
                  </span>
                  Cours
                </Link>
              </li>
              <li>
                <Link className="inline-flex" to="/tutoriels">
                  <span>
                    <FaDotCircle style={{ width: "20px", marginTop: "0.4rem", marginRight: "0.5rem" }} />
                  </span>
                  Tutoriels
                </Link>
              </li>
              <li className="mt-4">
                <a
                  className="inline-flex"
                  href="https://payet-portfolio.fr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Accès portfolio
                  <span>
                    <FaExternalLinkAlt style={{ width: "10px" }} />
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="inline-flex"
                  href="https://app.payet-portfolio.fr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Accès espace application
                  <span>
                    <FaExternalLinkAlt style={{ width: "10px" }} />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </Menu>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cours" component={Courses} />
          <Route exact path="/tutoriels" component={Tutorials} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </div>
  );
}
