import React from "react";

export default function Index() {
  return (
    <div
      className="flex flex-col w-screen"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h1 className="text-center uppercase mt-20">Les tutoriels</h1>
      <p className="font-bold font-italic text-center mt-10 text-xl">
        En cours de construction...
      </p>
    </div>
  );
}
