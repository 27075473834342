import React from "react";

export default function Home() {
  return (
    <div
      className="flex flex-col w-screen"
      style={{ backgroundColor: "aliceblue" }}
    >
      <h1 className="text-center mt-20">
        Bienvenue sur mon blog, c'est un espace qui sera consacré au cours,
        tutoriels et nouveautés !
      </h1>
      <h2 className="text-center italic pt-1">En cours de construction...</h2>
    </div>
  );
}
