import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'

import Header from './components/layouts/header'
import Sidebar from './components/tools/sidebar'
import Footer from './components/layouts/footer'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <div className="container">
      <Header />
      <Sidebar />
      <Footer />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals();
