import React from "react";

export default function Header() {
  return (
    <div
      className="fixed flex flex-row w-full h-16 justify-around items-center"
      style={{ backgroundColor: "#4b4e67", color: "white" }}
    >
      <h1>Espace blog</h1>
      {/* <div>
        <input type="text" placeholder="Rechercher..." className="text-black" />
        <button className="ml-1" type="submit">
          OK
        </button>
      </div> */}
    </div>
  );
}
